import { render, staticRenderFns } from "./mypage.vue?vue&type=template&id=3517fc3b&scoped=true&"
import script from "./mypage.vue?vue&type=script&lang=js&"
export * from "./mypage.vue?vue&type=script&lang=js&"
import style0 from "./mypage.vue?vue&type=style&index=0&id=3517fc3b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3517fc3b",
  null
  
)

/* custom blocks */
import block0 from "@/locales/mypage.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fviews%2Fmypage.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports