<template>
  <section class="theme-section">
    <vote-day class="vote-day">
      <img
        :src="require(`@images/common/${locale}/title_txt_day${theme.id}.png`)"
        alt=""
      />
    </vote-day>
    <section class="inner">
      <vote-theme
        :status="0"
        :class="{ '-hidden': theme.name === '?????' }"
        class="vote-theme"
      >
        <span v-html="theme.name" />
      </vote-theme>
      <div class="hero-items">
        <template v-for="n of loopNumber">
          <div
            :key="n"
            class="hero-item"
          >
            <img
              :src="getHeroItemSrc(heroes[n-1])"
              width="250"
              class="thumb"
            >
            <span class="condition" v-html="getConditionText(heroes[n-1])" />
          </div>
        </template>
      </div>
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import VoteDay from '@components/global/section-title.vue'
import VoteTheme from '@components/global/theme-title.vue'

export default {
  name: 'ThemeSection',

  components: {
    VoteDay,
    VoteTheme
  },

  props: {
    history: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapGetters('theme', { themeList: 'getThemes' }),

    theme () {
      const theme = this.themeList.filter(theme => +theme.id === +this.history.theme_id)
      return theme[0]
    },

    heroes () {
      return this.history.hero_ids
    },

    loopNumber () {
      return this.theme.type === 'pair' ? 2 : 1
    },

    locale () {
      return this.$route.params.lang
    },
  },

  methods: {
    getHeroItemSrc (heroId) {
      return heroId === undefined ?
        require('@images/mypage/theme_icon_bg.png') :
        require(`@images/hero/faces/lossless/${heroId}.png`)
    },

    getConditionText (heroId) {
      return heroId === undefined ? this.$t('mypage004') : this.$t('mypage003')
    }
  }
}
</script>

<i18n lang="yaml" src="@/locales/mypage.yaml"></i18n>

<style lang="scss" scoped>
$theme-z-index: (
  vote-day: 2
);

.theme-section {
  position: relative;
  min-height: 667px;
  background:
    url(#{$img-path}common/section_bg_symbol.png) no-repeat center 74px,
    url(#{$img-path}top/bg_theme_item.png) no-repeat center center;
  background-size: auto auto, auto 100%;
  color: $color-sub;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    background-repeat: no-repeat;
  }
  &:before {
    top: 0;
    left: 0;
    height: 16px;
    background-image: url(#{$img-path}top/top_line_blue.png);
  }
  &:after {
    left: 0;
    bottom: -5px;
    height: 18px;
    background-image: url(#{$img-path}top/btm_line_blue.png);
  }

  .vote-day {
    position: absolute;
    top: -72px;
    left: 0;
    width: 100%;
    z-index: map-get($theme-z-index, vote-day);
  }

  .inner {
    position: relative;
    height: 100%;
    padding-top: 105px;
  }
    .vote-theme {
      &.-hidden {
        font-size: $fs-base * 2 !important;
      }
    }
    .hero-items {
      display: flex;
      justify-content: center;
      margin-top: 72px;
    }
      .hero-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        + .hero-item {
          margin-left: 44px;
        }

        > .condition {
          display: block;
          margin-top: 2px;
        }
      }
}
</style>
