<template>
  <section class="wallpaper-modal">
    <div class="modal-inner">
      <div
        class="img-box"
        @click="openWallpaper"
      >
        <div class="light" ref="light"></div>
        <a
          :href="modal.props.link"
          class="img-link"
          target="_blank"
          rel="noopener"
        >
          <img
            :src="imageSrc"
            alt=""
            width="250"
            height="444"
            class="img"
            ref="wallpaper"
          >
        </a>
        <div class="icon" ref="icon"></div>
      </div>
      <div class="text-box">
        <h2
          class="title"
          v-html="`${$t('mypage008')}<br>${$t('mypage009')}`"
        />
        <p
          class="text"
          v-html="$t('mypage010')"
        />
      </div>
    </div>
  </section>
</template>

<i18n lang="yaml" src="@/locales/mypage.yaml"></i18n>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'

export default {
  name: 'WallpaperModal',

  components: {
  },

  data () {
    return {
      publicPath: process.env.BASE_URL,
      tls: {}
    }
  },

  computed: {
    ...mapState('modal', ['modal']),

    locale () {
      return this.$route.params.lang
    },

    imageSrc () {
      if (this.modal.props.indexNum === 0) return require('@images/mypage/thumb_wallpaper_3times.jpg')
      if (this.modal.props.indexNum === 1) return require('@images/mypage/thumb_wallpaper_5times.jpg')
      return null
    }
  },

  mounted () {
    const light = this.$refs.light
    
    this.tls.light = gsap.timeline()
    this.tls.light
      .add([
        gsap.to(light, {
          duration: 20,
          ease: 'none',
          repeat: -1,
          rotation: 360
        }),
        gsap.to(light, {
          duration: 2,
          ease: 'sine.inOut',
          repeat: -1,
          yoyo: true,
          scale: 0.85
        }),
      ])
    
    // hands
    const icon = this.$refs.icon
    this.tls.icon = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3,
    })
    this.tls.icon
      .to(icon, {
        x: "-=24",
        y: "-=24",
        duration: 0.3,
        ease: 'power1.in'
      })
      .to(icon, {
        x: "+=12",
        y: "+=12",
        duration: 0.15,
        ease: 'power1.out'
      })
      .to(icon, {
        x: "-=12",
        y: "-=12",
        duration: 0.15,
        ease: 'power1.in'
      })
      .to(icon, 0.3, {
        x: "+=24",
        y: "+=24",
        ease: 'power1.out'
      })
  },

  destroyed () {
    Object.values(this.tls).forEach(tl => tl.kill())
  },

  methods: {
    openWallpaper () {
      if (!this.image) {
        return
      }
      window.open(this.image)
    }
  }
}
</script>

<style lang="scss" scoped>
.wallpaper-modal {
  width: 722px;
  min-height: 1016px;
  border-style: solid;
  border-width: 52px 80px;
  border-image: url(#{$img-path}box/goldbox_bg.png) 52 80 fill stretch;

  > .modal-inner {
    padding-top: 17px;
    padding-bottom: 12px;

    > .img-box {
      position: absolute;
      top: 306px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
    }

    > .text-box {
      position: relative;
      z-index: 3;
      margin-top: 573px;
    }
  }
}

  .img-box {
    width: 1px;
    height: 1px;

    > .img-link {
      position: absolute;
      transform: translate(-50%, -50%) rotate(-15deg);
      display: block;
      width: 250px;
      height: 444px;

      &:hover {
        filter: none;
      }
    }

    > .light {
      $w: 738px;
      $h: 738px;
      background: url(#{$img-path}mypage/bg_light.png) no-repeat;
      position: absolute;
      width: $w;
      height: $h;
      top: -$w * 0.5 - 8px;
      left: -$h * 0.5;
    }
    > .icon {
      background: url(#{$img-path}mypage/icon_hand.png) no-repeat;
      position: absolute;
      top: 130px;
      left: 87px;
      width: 188px;
      height: 154px;
    }
  }

  .text-box {
    > .title {
      font-size: $fs-large;
      font-weight: 600;
      letter-spacing: 0.02em;
      text-align: center;
      line-height: lh(42, $fs-large);
    }
    > .text {
      font-size: $fs-middle;
      line-height: lh(40, $fs-middle);
      text-align: center;
      margin-top: 48px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
</style>