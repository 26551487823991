import Request, { Methods } from '@libraries/request/'

export default class VoteHistory extends Request {
  constructor() {
    super()
  }

  get path() {
    return '/api/v1.0.0/vote_history'
  }

  get method() {
    return Methods.get
  }

  get parameters() {
    const params = {}
    return params
  }

  decode({ data }) {
    return data.items
  }

  decodeError(error) {
    return { error: error }
  }
}