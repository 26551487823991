<template>
  <div class="mypage">
    <visual :confetti="false" />
    <section class="mypage-outline">
      <page-title level="2">
        <img
          :src="require(`@images/mypage/${this.locale}/title_txt.png`)"
          alt="Mypage"
        >
      </page-title>
      <div class="user">
        <img
          :src="iconImageSrc"
          alt=""
          class="thumb"
        >
        <p v-html="$t('mypage001', { nickname: user.nickname })" />
      </div>
      <p class="period" v-html="periodText">
      <p class="wallpaper-text" v-html="$t('mypage005')" />
      <animate-button
        :class="{ '-disabled': !this.wallpapers[0] }"
        @click="handleWallpaperButtonClick(0)"
        class="wallpaper-button"
      >
        <img
          :src="require(`@images/mypage/btn_wallpaper1_thumb.png`)"
          alt=""
          class="thumb"
          width="174"
          height="172"
        >
        <img
          :src="require(`@images/mypage/${this.locale}/btn_wallpaper1_txt.png`)"
          :alt="$t('mypage006')"
          class="text"
        >
      </animate-button>
      <animate-button
        :class="{ '-disabled': !this.wallpapers[1] }"
        @click="handleWallpaperButtonClick(1)"
        class="wallpaper-button"
      >
        <img
          :src="require(`@images/mypage/btn_wallpaper2_thumb.png`)"
          alt=""
          class="thumb"
        >
        <img
          :src="require(`@images/mypage/${this.locale}/btn_wallpaper2_txt.png`)"
          :alt="$t('mypage007')"
          class="text"
        >
      </animate-button>

      <div class="mypage-themes">
        <theme-section
          v-for="(history, i) in voteHistory"
          :key="i"
          :history="history"
        />
      </div>
    </section>

    <div class="action-buttons">
      <back-button @click="goBack" />
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sleep } from '@libraries/util/'
import Api from '@libraries/api/'
import RequestVoteHistory from '@requests/vote-history'
import ModalModel from '@models/modal'
import PageTitle from '@components/global/title.vue'
import Visual from '@components/global/visual.vue'
import BackButton from '@components/global/back-button.vue'
import WallpaperModal from '@components/modals/wallpaper-modal.vue'
import ThemeSection from '@components/mypage/theme-section.vue'
import AnimateButton from '@components/global/button-wrapper'

export default {
  name: 'Mypage',

  components: {
    Visual,
    PageTitle,
    BackButton,
    ThemeSection,
    AnimateButton
  },

  props: {
    locale: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      voteHistory: [],
      publicPath: process.env.BASE_URL
    }
  },

  computed: {
    ...mapGetters('login', ['user']),
    ...mapGetters('loader', { loaderConfig: 'config' }),
    ...mapGetters('wallpapers', { wallpapers: 'get' }),

    iconImageSrc () {
      return this.user && this.user.imageUrl || require('@images/mypage/icon_user.png')
    },

    periodText () {
      let baseText = this.$t('mypage002')

      if (this.locale.indexOf('fr') !== -1) {
        return baseText
      }

      const replacement = (this.locale === 'ja-jp' || this.locale === 'zh-tw')  ? '：' : ': '
      const index = baseText.indexOf(replacement)
      return baseText.substr(0, index + 1) + '<br>' + baseText.substr(index + replacement.length)
    }
  },

  mounted () {
    this.$nextTick(async () => {
      await Promise.all([
        await this.getVoteHistory(),
        await sleep(this.loaderConfig.minDisplayDuration)
      ])
      await this.hideLoader()
    })
  },

  methods: {
    ...mapActions('modal', { showModal: 'show' }),
    ...mapActions('loader', { hideLoader: 'hide' }),

    async getVoteHistory () {
      await Api.call(new RequestVoteHistory())
        .then((voteHistory) => {
          this.voteHistory = voteHistory
        })
        .catch(({error}) => {
          // eslint-disable-next-line
          console.error(error)
          this.$router.push({name: 'Error'})
        })
    },
    
    goBack () {
      this.$router.push({ name: 'Top' })
    },

    handleWallpaperButtonClick (index) {
      const file = this.wallpapers[index]

      if (!file) {
        return
      }

      this.showModal(new ModalModel({
        component: WallpaperModal,
        props: {
          link: `${this.publicPath}img/wallpaper/${file}`,
          indexNum: index,
        }
      }))
    }
  }
}
</script>

<i18n lang="yaml" src="@/locales/mypage.yaml"></i18n>

<style lang="scss" scoped>
.mypage {

}
  .mypage-outline {
    > .user {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fs-large;
      height: 120px;
      margin-top: 28px;
      margin-left: 70px;
      margin-right: 70px;
      > .thumb {
        border-radius: 50%;
        margin-right: 40px;
      }
    }
    > .period {
      width: 577px;
      margin: 20px auto 33px;
      text-align: center;
      padding: 30px;
      background: linear-gradient(
        to right,
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,.06) 3%,
        rgba(255,255,255,.2) 6%,
        rgba(255,255,255,.4) 10%,
        rgba(255,255,255,.67) 14%,
        rgba(255,255,255,.8) 16%,
        rgba(255,255,255,.8) 84%,
        rgba(255,255,255,.67) 86%,
        rgba(255,255,255,.29) 92%,
        rgba(255,255,255,.2) 94%,
        rgba(255,255,255,.06) 97%,
        rgba(255,255,255,0) 100%);
      position: relative;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 9px;
        left: 0;
        background: url(#{$img-path}common/label_bg_top.png) no-repeat;
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
        transform: scale(1, -1);
      }
    }
    .wallpaper-text {
      text-align: center;
      font-size: $fs-base * 1.7;
      font-weight: 600;
      padding-left: 40px;
      padding-right: 40px;
    }
    .wallpaper-button {
      background: url(#{$img-path}mypage/btn_wallpaper.png) no-repeat;
      position: relative;
      width: 620px;
      height: 130px;
      padding: 4px 88px 9px 216px;
      margin: 30px auto;
      display: flex;
      align-items: center;
      
      .thumb {
        position: absolute;
        top: -20px;
        left: 45px;
      }

      &.-disabled {
        pointer-events: none;
        > .thumb,
        > .text {
          opacity: 0.5;
        }
      }
    }

    .mypage-themes {
      margin-top: 120px;
      > .theme-section + .theme-section {
        margin-top: 110px;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: center;
    padding: 30px;
  }
</style>
