<template>
  <h3
    :class="[{ '-ing': status === 1, '-before': status === 2 }]"
    class="theme-title"
  >
    <slot />
  </h3>
</template>
<script>
export default {
  props: {
    status: {
      type: Number,
      default: null,
    }
  },

  computed: {
    locale () {
      return this.$route.params.lang
    }
  }
}
</script>
<style lang="scss" scoped>
.theme-title {
  $self: #{&};

  background: rgba(0, 0, 0, .6);
  font-size: $fs-base * 2;
  font-weight: 600;
  height: 120px;
  width: 610px;
  margin: 18px auto;
  display: flex;
  color: $color-sub;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  position: relative;

  @at-root {
    #app {
      &:not(.-ja-jp) {
        #{$self} {
          font-size: $fs-large;
          padding-left: 75px;
          padding-right: 75px;
        }
      }
      &.-de-de {
        #{$self} {
          font-size: $fs-middle;
        }
      }
      &.-fr-fr,
      &.-fr-ca,
      &.-pt-br {
        #{$self} {
          font-size: $fs-small;
        }
      }
      &.-es-es,
      &.-es-mx {
        #{$self} {
          font-size: $fs-xsmall;
        }
      }
      &.-zh-tw {
        #{$self} {
          font-size: $fs-base * 2;
        }
      }
    }
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 610px;
    height: 18px;
    background: url(#{$img-path}common/theme_bg2.png) no-repeat center bottom;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
  }

  &::before {
    top: -18px;
  }

  &::after {
    bottom: -19px;
  }

  &.-before {
    font-size: $fs-base * 2 !important;

    &::before,
    &::after {
      background: url(#{$img-path}common/theme_bg1.png) no-repeat center bottom;
    }
  }
  
  &.-ing {
    background: linear-gradient(to right, #ac8400 0%,#ba8c00 7%,#cc9a00 14%,#dbab00 20%,#edc300 25%,#f9d401 31%,#fcda0c 33%,#fedf1e 35%,#ffe958 42%,#fff189 47%,#fff39b 50%,#fff189 53%,#ffe958 58%,#fedf1e 65%,#fcda0c 67%,#f9d401 70%,#edc300 75%,#cf9d00 85%,#ba8c00 93%,#ac8400 100%);
    color: $color-base;
    box-shadow: inset 0px 0px 3px 2px rgba(0, 0, 0, .25);
    &::before,
    &::after {
      background: url(#{$img-path}common/theme_bg1.png) no-repeat center bottom;
    }
  }
}
</style>
